<template>
        <div class="form-group">
            <slot name="label">
            <label :class="[labelClasses, { 'is-required': labelRequired }]" v-if="label">{{ label }}</label>
            </slot>
            <validation-provider :rules="rules" :name="name" v-bind="$attrs" v-slot="{ errors, valid, invalid, validated }" ref="validationProvider">
            <div class="custom-editor">
            <editor :value="value" :api-key="editorApiKey" :init="editorOptionMessage" v-on="listeners" v-bind="$attrs"
                ref="editor" />
            <div class="character-count-editor"  v-if="showCharacterCount">
                <p>{{ `${characterCount} /${maxlength}` }}</p>&nbsp;
                <p class="character-count-text"> Characters Limit</p>
            </div>
            </div>
            <div v-if="errors[0]" class="invalid-feedback mb-4" style="display: block;">
                    {{ errors[0] }}
                </div>
            </validation-provider>
        </div>
</template>
<script>

export default {
    inheritAttrs: false,
    name: "base-editor",
    props: {
        label: {
            type: String,
            description: "Input label (text before input)"
        },
        labelClasses: {
            type: String,
            description: "Input label css classes",
            default: "form-control-label"
        },
        value: {
            type: [String, Number],
            description: "Input value"
        },
        rules: {
            type: [String, Array, Object],
            description: 'Vee validate validation rules',
            default: ''
        },
        name: {
            type: String,
            description: 'Input name (used for validation)',
            default: ''
        },
        maxlength: {
            type: [Number, String],
            description: 'Max Input Length',
            default: 250
        },
        labelRequired: {
            type: Boolean,
            default: false
        },
        height: {
            type: Number,
            default: 200
        }
    },
    data() {
        let vm = this;

        return {
            showCharacterCount: false,
            characterCount: 0,
            editorApiKey: process.env.VUE_APP_EDITOR_API_KEY,
            tempInput: '',
            editorOptionMessage: {
                height: vm.height,
                plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                menubar: '',
                toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify| image  | outdent indent |  numlist bullist | forecolor backcolor removeformat | ltr rtl',
                content_style: 'body { font-family:Arial,Helvetica,sans-serif; font-size:16px; line-height: 1.5; }',
                fontsize_formats: '4px 6px 8px 10px 12px 14px 16px 18px 20px 22px 24px 26px 28px 36px'
                , statusbar: false, setup: function (editor) {
                    editor.on('init', function (e,ed) {
                        editor.execCommand("fontName", false, "Arial");
                        editor.execCommand("fontSize", false, "16");
                        let numChars = editor?.plugins?.wordcount?.body?.getCharacterCount() || 0;
                        vm.characterCount = numChars;
                        vm.showCharacterCount = true;
                    });
                    editor.on('keydown keyup keypress paste change SetContent', function (e) {
                        let numChars = editor?.plugins?.wordcount?.body?.getCharacterCount() || 0;
                        vm.characterCount = numChars;

                        if (numChars > vm.maxlength && ![8, 46, 37, 38, 39, 40, 16, 17].includes(e.keyCode)) {
                            // let content =editor.getContent({ format: 'text' });
                            e.preventDefault();
                            e.stopPropagation();
                            editor.setContent(vm.tempInput)
                            // Focus the editor
                            editor.focus();
                            // Get the editor's selection object
                            var selection = editor.selection;
                            // Set the cursor to the end of the content
                            selection.select(editor.getBody(), true);
                            selection.collapse(false);
                            return false;
                        }
                        vm.tempInput = editor.getContent();
                        numChars = editor?.plugins?.wordcount?.body?.getCharacterCount() || 0;
                        vm.characterCount = numChars;
                        if (numChars == 0) {
                            editor.execCommand("fontName", false, "Arial");
                            editor.execCommand("fontSize", false, "16");
                        }
                    });
                }
            },
        };
    },
    computed: {
        listeners() {
            return {
                ...this.$listeners,
                input: this.updateValue,
                focus: this.onFocus,
                blur: this.onBlur
            };
        },
    },
    methods: {
        updateValue(value) {
            this.$refs.validationProvider.validate(value);
            this.$emit("input", value);
        },
        onFocus(evt) {
            this.focused = true;
            this.$emit("focus", evt);
        },
        onBlur(evt) {
            this.focused = false;
            this.$emit("blur", evt);
        },
    }
};
</script>
<style>
.is_required::after {
    content: "*";
    color: red;
}
</style>
  